import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { MatButtonToggle } from '@angular/material/button-toggle';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-form-control-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormControlMarkdownComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: FormControlMarkdownComponent,
      multi: true,
    },
  ],
})
export class FormControlMarkdownComponent
  implements OnDestroy, ControlValueAccessor, Validator
{
  @Input()
  label = '';

  @ViewChild('textAreaElementRef')
  private textAreaElementRef!: ElementRef;

  public textAreaFormControl: FormControl;
  public selectedButtons: MatButtonToggle[];
  public isRequired$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  private valueChangeSubscription: Subscription | null;
  private onChange: ((value: string) => void) | null;
  private onTouched: (() => void) | null;

  constructor() {
    this.textAreaFormControl = new FormControl('', { updateOn: 'blur' });
    this.selectedButtons = [];
    this.onChange = null;
    this.onTouched = null;

    this.valueChangeSubscription =
      this.textAreaFormControl.valueChanges.subscribe((value: string) => {
        if (this.onChange != null) {
          this.onChange(value);
        }

        if (this.onTouched != null) {
          this.onTouched();
        }
      });
  }

  ngOnDestroy() {
    this.valueChangeSubscription?.unsubscribe();
  }

  // implements ControlValueAccessor
  writeValue(value: string): void {
    this.textAreaFormControl.setValue(value);
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  // end implements ControlValueAccessor

  // implements Validator
  validate(control: AbstractControl): ValidationErrors | null {
    if (control.hasValidator(Validators.required)) {
      this.isRequired$.next(true);
    }

    this.textAreaFormControl.setValidators(control.validator);

    if (control.touched) {
      this.textAreaFormControl.markAllAsTouched();
    }

    return null;
  }
  // end implements Validator

  // control strip
  public prependSelectedLines(event: MouseEvent, token: string) {
    event.preventDefault();
    event.stopPropagation();

    const textAreaElement = this.textAreaElementRef
      .nativeElement as HTMLTextAreaElement;

    if (document.activeElement !== textAreaElement) {
      return;
    }

    let selectionStart = textAreaElement.selectionStart;
    let selectionEnd = textAreaElement.selectionEnd;

    const getLineIndex = (cursorPosition: number): number => {
      const lines = textAreaElement.value
        .substring(0, cursorPosition)
        .split('\n');
      return lines.length - 1;
    };

    const lines = textAreaElement.value.split('\n');
    const startLineIndex = getLineIndex(selectionStart);
    const endLineIndex = getLineIndex(selectionEnd);

    for (let i = startLineIndex; i <= endLineIndex; i++) {
      let currentLine: string = lines[i];

      if (currentLine.startsWith(token)) {
        currentLine = currentLine.substring(token.length);

        if (i === 0) {
          selectionStart -= token.length;
        }

        selectionEnd -= token.length;
      } else {
        currentLine = token + currentLine;

        if (i === 0) {
          selectionStart += token.length;
        }

        selectionEnd += token.length;
      }

      lines[i] = currentLine;
    }

    const text = lines.join('\n');
    this.textAreaFormControl.setValue(text);

    textAreaElement.setSelectionRange(selectionStart, selectionEnd);
  }

  surroundSelection(event: MouseEvent, tokenStart: string, tokenEnd: string) {
    event.preventDefault();
    event.stopPropagation();

    const textAreaElement = this.textAreaElementRef
      .nativeElement as HTMLTextAreaElement;

    if (document.activeElement !== textAreaElement) {
      return;
    }

    let selectionStart = textAreaElement.selectionStart;
    let selectionEnd = textAreaElement.selectionEnd;

    const value: string = textAreaElement.value;
    const selectionStartPrefix = value.slice(
      selectionStart - tokenStart.length,
      selectionStart,
    );
    const selectionEndSuffix = value.slice(
      selectionEnd,
      selectionEnd + tokenEnd.length,
    );

    let text;
    if (
      selectionStartPrefix === tokenStart &&
      selectionEndSuffix === tokenEnd
    ) {
      text =
        value.slice(0, selectionStart - tokenStart.length) +
        value.slice(selectionStart, selectionEnd) +
        value.slice(selectionEnd + tokenEnd.length);

      selectionStart -= tokenStart.length;
      selectionEnd -= tokenStart.length;
    } else {
      text =
        value.slice(0, selectionStart) +
        tokenStart +
        value.slice(selectionStart, selectionEnd) +
        tokenEnd +
        value.slice(selectionEnd);

      selectionStart += tokenStart.length;
      selectionEnd += tokenStart.length;
    }

    this.textAreaFormControl.setValue(text);

    textAreaElement.setSelectionRange(selectionStart, selectionEnd);
  }
}

import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked, Tokens } from 'marked';

class CustomRenderer extends marked.Renderer {
  override heading(heading: Tokens.Heading): string {
    return `<h${heading.depth + 2}>${heading.text}</h${heading.depth + 2}>`;
  }
}

// transforms a string containing Markdown syntax to SafeHtml
@Pipe({ name: 'markdown' })
export class MarkdownPipe implements PipeTransform {
  private renderer: CustomRenderer;

  constructor(private sanitizer: DomSanitizer) {
    this.renderer = new CustomRenderer();
  }

  transform(value: string | null | undefined): SafeHtml {
    if (!value) {
      return '';
    }

    const html = marked.parse(value, {
      renderer: this.renderer,
    }) as string;

    const result = this.sanitizer.sanitize(SecurityContext.HTML, html) || '';

    return result as SafeHtml;
  }
}

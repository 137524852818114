import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import * as geojson from 'geojson';
import * as L from 'leaflet';
import 'leaflet-draw';
import { Projection, TilesetStyle, getProjection } from '@models/leaflet';
import {
  ErrorMessageControl,
  CursorPositionControl,
  ProjectionFilterControl,
  ProjectionSelectorControl,
} from '@models/leaflet';
import { MediaService, ThemeStyle } from '@services';
import { Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { GeoJSONFormComponent } from './geojson-form/geojson-form.component';

L.Icon.Default.imagePath = 'assets/leaflet/images/';

@Component({
  selector: 'app-leaflet',
  templateUrl: './leaflet.component.html',
  styleUrls: ['./leaflet.component.scss'],
})
export class LeafletComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input({ required: true })
  geojson$!: Observable<geojson.FeatureCollection | null>;

  @Input() isReadOnly = true;
  @Input() projectionName = 'EPSG3857';
  @Input() doFilter = false;
  @Input() initialView: {
    bounds: L.LatLngBoundsExpression;
    zoom: number | undefined;
  } | null = null;

  @ViewChild('mapElement')
  mapElementRef!: ElementRef<HTMLElement>;

  @Output()
  geometryChanged = new EventEmitter<geojson.FeatureCollection>();

  private map!: L.Map;
  private tileLayer!: L.TileLayer;
  private drawLayer!: L.GeoJSON;
  private projection!: Projection;
  private filteredFeatures: geojson.Feature[];
  private tilesetStyle: TilesetStyle;

  private errorMessageBoxControl: ErrorMessageControl | null;
  private projectionSelectorControl: ProjectionSelectorControl | null;
  private projectionFilterControl: ProjectionFilterControl | null;
  private themeSubscription: Subscription | null;
  private geojsonSubscription: Subscription | null;

  constructor(
    private mediaService: MediaService,
    private dialog: MatDialog,
  ) {
    this.filteredFeatures = [];

    this.errorMessageBoxControl = null;
    this.projectionSelectorControl = null;
    this.projectionFilterControl = null;

    this.themeSubscription = null;
    this.geojsonSubscription = null;

    this.tilesetStyle = TilesetStyle.Light;
  }

  ngOnInit() {
    // https://github.com/Leaflet/Leaflet.draw/issues/1005
    window.type = undefined;
  }

  ngAfterViewInit() {
    this.themeSubscription = this.mediaService.theme$.subscribe(
      (theme: ThemeStyle) => {
        const style =
          theme == ThemeStyle.Dark ? TilesetStyle.Dark : TilesetStyle.Light;

        if (style === this.tilesetStyle) {
          return;
        }

        this.tilesetStyle = style;
        if (this.map) {
          this.reset(true);
        }
      },
    );

    this.geojsonSubscription = this.geojson$.subscribe(
      (data: geojson.FeatureCollection | null) => {
        this.reset(false, data);
      },
    );
  }

  ngOnDestroy() {
    this.map?.off();
    this.map?.remove();

    this.mapElementRef?.nativeElement?.remove();

    // https://github.com/Leaflet/Leaflet.draw/issues/1005
    delete window.type;

    this.themeSubscription?.unsubscribe();
    this.geojsonSubscription?.unsubscribe();
  }

  public openGeoJsonDialog() {
    const dialogRef = this.dialog.open(GeoJSONFormComponent, {
      minWidth: '360px',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((polygon: geojson.Polygon | null) => {
      if (!polygon) {
        return;
      }

      try {
        const feature = {
          type: 'Feature',
          properties: {
            projection: this.projectionName,
          },
          geometry: polygon,
        } as geojson.GeoJsonObject;

        this.drawLayer.addData(feature);
        this.geometryChanged.emit(this.getGeoJSON()!);
        this.updateBadges();
      } catch (err) {
        this.handleGeoJSONError(err as string);
      }
    });
  }

  public get hasLayers() {
    return this.drawLayer.getLayers().length > 0;
  }

  private addCursorPositionControl() {
    new CursorPositionControl({
      position: 'bottomright',
    }).addTo(this.map);
  }

  private addErrorControl() {
    this.errorMessageBoxControl = new ErrorMessageControl({
      position: 'topright',
    }).addTo(this.map);
  }

  private addDrawControls() {
    if (this.isReadOnly) {
      return;
    }

    const drawControl = new L.Control.Draw({
      draw: {
        // calling .getGeoJSON() on a 'circle' type layer gives a point and not a polygon.
        // radius, lat and long are available though, so it's possible to generate valid GeoJSON
        // manually if we really want to support drawing circles
        circle: false,
      },
      edit: {
        featureGroup: this.drawLayer,
      },
    });

    this.map.addControl(drawControl);

    this.map.on(L.Draw.Event.CREATED, (e) => {
      const feature = e.layer.toGeoJSON();
      this.drawLayer.addData(feature);
      this.geometryChanged.emit(this.getGeoJSON()!);
      this.updateBadges();
    });
    this.map.on(L.Draw.Event.EDITED, () => {
      this.geometryChanged.emit(this.getGeoJSON()!);
      this.updateBadges();
    });
    this.map.on(L.Draw.Event.DELETED, () => {
      this.geometryChanged.emit(this.getGeoJSON()!);
      this.updateBadges();
    });
  }

  private addProjectionControls() {
    this.projectionSelectorControl = new ProjectionSelectorControl({
      position: 'topright',
    }).addTo(this.map);

    for (const projection in this.projectionSelectorControl.buttons) {
      if (projection === this.projectionName) {
        continue;
      }

      const buttonElement = this.projectionSelectorControl.buttons[projection];
      buttonElement.addEventListener('click', () => {
        this.projectionName = projection;
        this.reset();
      });
    }

    this.projectionFilterControl = new ProjectionFilterControl({
      position: 'topright',
    }).addTo(this.map);
    this.projectionFilterControl.setState(this.doFilter);
    this.projectionFilterControl.filterButtonElement.addEventListener(
      'click',
      () => {
        this.doFilter = !this.doFilter;
        this.reset(true);
      },
    );

    this.projectionSelectorControl.setActiveProjection(this.projectionName);
    this.updateBadges();
  }

  private getGeoJSON() {
    if (!this.drawLayer) {
      return null;
    }

    const featureCollection =
      this.drawLayer.toGeoJSON() as geojson.FeatureCollection;
    for (const feature of this.filteredFeatures) {
      featureCollection.features.push(feature);
    }

    return featureCollection;
  }

  private inNorthernHemisphere(bounds: L.LatLngBounds) {
    const northBounds = L.latLngBounds(L.latLng(0, -180), L.latLng(90, 180));
    return (
      northBounds.contains(bounds) ||
      northBounds.overlaps(bounds) ||
      northBounds.intersects(bounds)
    );
  }

  private inSouthernHemisphere(bounds: L.LatLngBounds) {
    const southBounds = L.latLngBounds(L.latLng(-90, -180), L.latLng(0, 180));
    return (
      southBounds.contains(bounds) ||
      southBounds.overlaps(bounds) ||
      southBounds.intersects(bounds)
    );
  }

  private handleGeoJSONError(err: string) {
    console.error('Leaflet', err);
    this.errorMessageBoxControl?.show(err);
  }

  private updateBadges() {
    if (!this.projectionSelectorControl) {
      return;
    }

    const counters: { [key: string]: number } = {};

    const data = this.getGeoJSON();
    if (data) {
      for (const feature of data.features) {
        const projectionName = feature.properties?.['projection'];
        if (!projectionName) {
          continue;
        }

        counters[projectionName] = counters[projectionName] || 0;
        counters[projectionName] += 1;
      }
    }

    this.projectionSelectorControl.setBadgeValues(counters);
  }

  public reset(
    preserveView = false,
    geoJson: geojson.FeatureCollection | null | undefined = undefined,
  ) {
    const currentGeoJSON = geoJson !== undefined ? geoJson : this.getGeoJSON();
    let currentView = this.initialView;

    if (this.map) {
      if (preserveView) {
        currentView = {
          bounds: this.map.getBounds(),
          zoom: this.map.getZoom(),
        };
      }

      this.map.off();
      this.map.remove();
    }

    this.filteredFeatures = [];

    this.projection = getProjection(this.projectionName, this.tilesetStyle);
    this.map = L.map('map', {
      crs: this.projection.crs,
    });

    this.tileLayer = L.tileLayer(this.projection.tilesetSource, {
      tileSize: this.projection.tileSize,
      minZoom: this.projection.minZoom,
      maxZoom: this.projection.maxZoom,
      zoomOffset: this.projection.zoomOffset,
      noWrap: true,
      bounds: this.projection.tileBounds,
    });

    this.drawLayer = L.geoJson(undefined, {
      onEachFeature: (feature) => {
        if (!feature.properties?.projection) {
          feature.properties.projection = this.projectionName;
        }
      },
      style: (feature) => {
        const featureProjectionName = feature?.properties?.projection;
        if (featureProjectionName) {
          return {
            className: getProjection(featureProjectionName, this.tilesetStyle)
              .className,
          };
        }

        return {
          className: this.projection.className,
        };
      },
      filter: (feature) => {
        if (this.doFilter) {
          if (
            feature.properties?.projection &&
            feature.properties?.projection !== this.projectionName
          ) {
            this.filteredFeatures.push(feature);
            return false;
          }
        } else {
          // Convert geoJSON coordinates to Lat Lng bounds. This is used to check
          // if the current feature overlays the selected projection box.
          let bounds: L.LatLngBounds | null = null;

          switch (feature?.geometry?.type) {
            case 'Point': {
              bounds = L.latLngBounds(
                L.GeoJSON.coordsToLatLng(
                  feature.geometry.coordinates as [number, number],
                ),
                L.GeoJSON.coordsToLatLng(
                  feature.geometry.coordinates as [number, number],
                ),
              );
              break;
            }
            case 'Polygon': {
              const coords = L.GeoJSON.coordsToLatLngs(
                feature.geometry.coordinates,
                1,
              );
              bounds = L.latLngBounds(coords);
              break;
            }
            case 'LineString': {
              if (feature.geometry.coordinates.length == 0) {
                return false;
              }

              let minLat: number,
                minLng: number,
                maxLat: number,
                maxLng: number;
              minLat = maxLat = feature.geometry.coordinates[0][0];
              minLng = maxLng = feature.geometry.coordinates[0][1];

              for (let i = 1; i < feature.geometry.coordinates.length; i++) {
                const coord = feature.geometry.coordinates[i];

                // Update Lngs
                if (coord[0] < minLng) {
                  minLng = coord[0];
                } else if (coord[0] > maxLng) {
                  maxLng = coord[0];
                }

                // Update Lats
                if (coord[1] < minLat) {
                  minLat = coord[1];
                } else if (coord[1] > maxLat) {
                  maxLat = coord[1];
                }
              }

              bounds = L.latLngBounds(
                L.latLng(minLat, minLng),
                L.latLng(maxLat, maxLng),
              );
              break;
            }
          }

          // Reject features based on the current projection
          if (bounds) {
            switch (this.projectionName) {
              case 'EPSG3575': // Northern hemisphere polar stereographic
                if (!this.inNorthernHemisphere(bounds)) {
                  this.filteredFeatures.push(feature);
                  return false;
                }
                break;
              case 'EPSG3031': // Southern hemisphere polar stereographic
                if (!this.inSouthernHemisphere(bounds)) {
                  this.filteredFeatures.push(feature);
                  return false;
                }
                break;
            }
          }
        }

        return true;
      },
    });

    this.map.addLayer(this.tileLayer);
    this.map.addLayer(this.drawLayer);

    this.map.attributionControl.setPosition('bottomleft');

    this.addCursorPositionControl();
    this.addErrorControl();
    this.addDrawControls();

    if (currentGeoJSON) {
      try {
        this.drawLayer.addData(currentGeoJSON);
      } catch (err) {
        this.handleGeoJSONError(err as string);
      }
    }

    this.addProjectionControls();

    // make sure any drawn layers are visible
    if (currentView) {
      this.map.fitBounds(currentView.bounds, {
        maxZoom: currentView.zoom,
      });
    } else if (this.hasLayers) {
      try {
        const layers = this.drawLayer.getLayers();
        const group = L.featureGroup(layers);
        const bounds = group.getBounds();

        this.map.fitBounds(bounds, { maxZoom: this.projection.minZoom });
      } catch (err) {
        console.error('failed to fit layers into view', err);
      }
    } else {
      this.map.setView(this.projection.center, this.projection.minZoom);
    }

    this.map.setMaxBounds(this.projection.viewBounds);
  }
}

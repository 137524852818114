import { Component, Input, OnInit } from '@angular/core';
import { Citation, Dataset } from '@models';
import { DatasetType } from '@models';

@Component({
  selector: 'app-dataset-citation',
  templateUrl: './citation.component.html',
  styleUrls: ['./citation.component.scss'],
})
export class CitationComponent implements OnInit {
  @Input({ required: true })
  dataset!: Dataset;
  public citation: Citation | null;
  public showInterimCCBY = true;

  public DatasetType = DatasetType;

  constructor() {
    this.citation = null;
  }

  ngOnInit() {
    this.citation = new Citation(this.dataset);
  }
}

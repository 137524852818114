import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
  Breadcrumb,
  Citation,
  Dataset,
  DatasetListResponse,
  DatasetType,
  User,
} from '@models';
import { AuthService, DatasetService, NotificationService } from '@services';
import {
  BehaviorSubject,
  Subscription,
  debounce,
  first,
  tap,
  timer,
} from 'rxjs';
import { DatasetCreateFormComponent } from '../create-form/form.component';

interface QueryParams {
  page: number | undefined;
  pageSize: number | undefined;
  q: string | undefined;
  location: string | undefined;
  type: string | undefined;
  doCount: boolean;
}

@Component({
  selector: 'app-dataset-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class DatasetListComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  public DatasetType = DatasetType;
  public breadcrumbs: Breadcrumb[] = [
    {
      title: 'Home',
      url: '/',
    },
    {
      title: 'Datasets',
      url: '/dataset',
    },
  ];

  public dataSource: MatTableDataSource<Dataset>;
  public defaultPageSize = 10;
  public searchForm: FormGroup;
  public isLoading$: BehaviorSubject<boolean>;

  private subscriptions: Subscription[];

  constructor(
    public authService: AuthService,
    private datasetService: DatasetService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
  ) {
    this.searchForm = new FormGroup({
      search: new FormControl(''),
      location: new FormControl('', []),
      type: new FormControl(
        { value: '', disabled: !authService.isLoggedIn },
        {
          validators: [],
        },
      ),
    });
    this.dataSource = new MatTableDataSource<Dataset>();
    this.subscriptions = [];
    this.isLoading$ = new BehaviorSubject<boolean>(true);
  }

  ngOnInit() {
    // must listen to user login/logout in this component, as both actions redirect here
    const userSubscription = this.authService.user$.subscribe(
      (u: User | null) => {
        const control = this.searchForm.controls['type'];
        if (
          (u === null && control.disabled) ||
          (u !== null && control.enabled)
        ) {
          return;
        }

        if (u === null) {
          control.disable({
            emitEvent: false,
          });
        } else {
          control.enable({
            emitEvent: false,
          });
        }

        this.paginator.length = 0;
        this.reloadData({});
      },
    );

    const routeParamsSubscription = this.route.queryParams.subscribe(
      (params: Params) => {
        this.reloadData(params);
      },
    );

    const searchFormSubscription = this.searchForm.valueChanges
      .pipe(
        tap(() => {
          this.isLoading$.next(true);
        }),
        debounce(() => timer(500)),
      )
      .subscribe(() => {
        this.paginator.length = 0;

        this.router.navigate(['/dataset'], {
          queryParams: {
            page: undefined,
            pageSize:
              this.paginator.pageSize === this.defaultPageSize
                ? undefined
                : this.paginator.pageSize,
            q: this.searchForm.value.search || undefined,
            location: this.searchForm.value.location || undefined,
            type: this.searchForm.value.type || undefined,
          } as QueryParams,
        });
      });

    this.subscriptions.push(routeParamsSubscription);
    this.subscriptions.push(searchFormSubscription);
    this.subscriptions.push(userSubscription);
  }

  ngOnDestroy() {
    for (const s of this.subscriptions) {
      s.unsubscribe();
    }
  }

  getCitation(dataset: Dataset): Citation {
    return new Citation(dataset);
  }

  applyLocationFilter(location: string | undefined | null) {
    this.searchForm.controls['location'].setValue(location);
  }

  applyTypeFilter(type: string | undefined | null) {
    this.searchForm.controls['type'].setValue(type);
  }

  clearFilters() {
    this.searchForm.controls['location'].setValue(null);
    this.searchForm.controls['type'].setValue(null);
  }

  get activeFilters(): string[] {
    return [this.searchForm.value.location, this.searchForm.value.type];
  }

  changePage(event: PageEvent) {
    this.router.navigate(['/dataset'], {
      queryParams: {
        page: event.pageIndex === 0 ? undefined : event.pageIndex,
        pageSize:
          event.pageSize === this.defaultPageSize ? undefined : event.pageSize,
        q: this.searchForm.value.search || undefined,
        location: this.searchForm.value.location || undefined,
        type: this.searchForm.value.type || undefined,
      } as QueryParams,
    });
  }

  onNewDatasetClick() {
    const dialogRef = this.dialog.open(DatasetCreateFormComponent, {
      minWidth: '360px',
    });

    dialogRef.afterClosed().subscribe((title: string) => {
      if (!title) {
        return;
      }

      this.datasetService.createDataset(title).subscribe({
        next: (dataset: Dataset) => {
          this.router.navigate(['dataset', dataset.id, 'edit']);
        },
        error: (err) => {
          console.error(err);
          this.notificationService.error('Failed to create a new dataset.');
        },
      });
    });
  }

  private reloadData(queryParams: Params) {
    const pageIndex = queryParams['page'] || 0;
    const pageSize = queryParams['pageSize'] || this.defaultPageSize;
    const doCount = this.paginator ? this.paginator.length === 0 : true;

    const search = queryParams['q'] || undefined;
    const location = queryParams['location'] || undefined;
    const type = queryParams['type'] || undefined;

    this.searchForm.setValue(
      {
        search: search || '',
        location: location || '',
        type: type || '',
      },
      {
        emitEvent: false,
      },
    );

    this.datasetService
      .getDatasets(pageIndex * pageSize, pageSize, doCount, {
        search: search,
        location: location,
        type: type,
      })
      .pipe(first())
      .subscribe({
        next: (result: DatasetListResponse) => {
          if (doCount) {
            this.paginator.length = result.count;
            this.paginator.firstPage();
          }

          this.dataSource = new MatTableDataSource<Dataset>(result.items);
        },
        error: (err) => {
          console.error(err);

          if (err?.status !== 401) {
            this.notificationService.error('Failed to load datasets.');
          }
        },
      })
      .add(() => {
        this.isLoading$.next(false);
      });
  }
}

<h2 mat-dialog-title>{{ attachment.title || attachment.filename }}</h2>

<mat-dialog-content>
  <mat-list>
    <mat-list-item>
      <div matListItemTitle>Filename</div>
      <div matListItemLine>{{ attachment.filename }}</div>
    </mat-list-item>

    <mat-list-item>
      <div matListItemTitle>Prefix</div>
      <div matListItemLine>{{ attachment.prefix }}</div>
    </mat-list-item>

    @if (attachment.title) {
      <mat-list-item>
        <div matListItemTitle>Title</div>
        <div matListItemLine>{{ attachment.title }}</div>
      </mat-list-item>
    }

    @if (attachment.description) {
      <mat-list-item>
        <div matListItemTitle>Description</div>
        <div matListItemLine>{{ attachment.description }}</div>
      </mat-list-item>
    }

    <mat-list-item>
      <div matListItemTitle>Release date</div>
      <div matListItemLine>{{ attachment.released | date: 'YYYY-MM-dd' }}</div>
    </mat-list-item>

    <mat-list-item>
      <div matListItemTitle>Mime type</div>
      <div matListItemLine>{{ attachment.mimeType }}</div>
    </mat-list-item>

    <mat-list-item>
      <div matListItemTitle>Size</div>
      <div matListItemLine>{{ attachment.byteSize | humanReadableSize }}</div>
    </mat-list-item>

    <mat-list-item>
      <div matListItemTitle>Created</div>
      <div matListItemLine
        >{{ attachment.created | date: 'YYYY-MM-dd' }} by
        @if (attachment.createdBy | resolveUser | async; as user) {
          <a href="mailto:{{ user.email }}">{{ user.email }}</a>
        } @else {
          {{ attachment.createdBy }}
        }
      </div>
    </mat-list-item>

    <mat-list-item>
      <div matListItemTitle>Modified</div>
      <div matListItemLine
        >{{ attachment.created | date: 'YYYY-MM-dd' }} by
        @if (attachment.modifiedBy | resolveUser | async; as user) {
          <a href="mailto:{{ user.email }}">{{ user.email }}</a>
        } @else {
          {{ attachment.modifiedBy }}
        }
      </div>
    </mat-list-item>

    <mat-list-item>
      <span matListItemTitle>SHA256</span>
      <span matListItemLine>{{ attachment.sha256 | slice: 0 : 50 }}</span>
      <span matListItemLine>{{ attachment.sha256 | slice: 50 }}</span>
    </mat-list-item>
  </mat-list>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <a
    mat-flat-button
    color="primary"
    href="{{ fileHostUrl }}/dataset/{{ attachment.datasetId }}/attachment/{{
      attachment.id
    }}/_blob"
    download
    [appAuthorize]="!isPublic || !isDatasetPublic"
    >Download</a
  >
  <button mat-button (click)="dialogRef.close(false)">Close</button>
</mat-dialog-actions>
